var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        { attrs: { label: "副标题" } },
        [
          _c("el-input", {
            staticStyle: { height: "50px", width: "300px" },
            attrs: { type: "textarea", placeholder: "" },
            model: {
              value: _vm.design.pageTitle,
              callback: function ($$v) {
                _vm.$set(_vm.design, "pageTitle", $$v)
              },
              expression: "design.pageTitle",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "主题色" } },
        [
          _c("el-color-picker", {
            attrs: { placeholder: "", "color-format": "hex" },
            model: {
              value: _vm.design.themeColor,
              callback: function ($$v) {
                _vm.$set(_vm.design, "themeColor", $$v)
              },
              expression: "design.themeColor",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Banner图" } },
        [
          _c(
            "div",
            _vm._l(_vm.design.bannerFiles, function (file, index) {
              return _c("img", {
                key: index,
                staticStyle: {
                  width: "90px",
                  height: "60px",
                  padding: "2px 5px",
                  "border-radius": "8px",
                  cursor: "pointer",
                },
                attrs: {
                  src:
                    file.url + "?x-oss-process=image/resize,m_fixed,h_80,w_120",
                },
                on: {
                  click: function ($event) {
                    _vm.designForm.cover = _vm.src
                  },
                },
              })
            }),
            0
          ),
          _vm.formFilesUpload
            ? _c(
                "el-upload",
                {
                  ref: "uploader",
                  staticClass: "upload-demo",
                  attrs: {
                    headers: { Authorization: _vm.Authorization },
                    action: "/api/ali/upload",
                    multiple: "",
                    data: _vm.uploadParam,
                    "auto-upload": true,
                    "show-file-list": false,
                    "file-list": _vm.design.bannerFiles,
                    limit: 10,
                    "http-request": _vm.formFilesUpload.elFileUpload,
                    "before-upload": _vm.onBeforeFileUpload,
                    "on-exceed": _vm.onHandleFileExceed,
                    "on-success": _vm.onHandleFileSuccess,
                    "before-remove": function (params) {
                      return _vm.onBeforeFileRemove(params)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { "text-align": "left" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            "border-color": "#3476f0",
                            color: "#3476f0",
                          },
                          attrs: { icon: "el-icon-plus", size: "mini" },
                        },
                        [_vm._v("上传banner图片")]
                      ),
                      _c("div", { staticClass: "el-upload__text" }),
                      _c("div", { staticClass: "el-upload__text" }, [
                        _vm.type == "2"
                          ? _c("div", [
                              _vm._v(
                                " 建议上传宽高比为 4.5:1 的图片,例如: 1280 x 280，不超过2MB "
                              ),
                            ])
                          : _c("div", [
                              _vm._v(
                                " 建议上传宽高比为 2.8:1 的图片,例如: 1280 x {1280/2.8}，不超过2MB "
                              ),
                            ]),
                      ]),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "未登录Logo" } },
        [
          _c(
            "div",
            _vm._l(_vm.design.unloginLogo, function (file, index) {
              return _c("img", {
                key: index,
                staticStyle: {
                  width: "60px",
                  height: "60px",
                  padding: "2px 5px",
                  "border-radius": "8px",
                  cursor: "pointer",
                },
                attrs: {
                  src:
                    file.url + "?x-oss-process=image/resize,m_fixed,h_60,w_60",
                },
                on: {
                  click: function ($event) {
                    _vm.designForm.cover = _vm.src
                  },
                },
              })
            }),
            0
          ),
          _vm.logoUpload
            ? _c(
                "el-upload",
                {
                  ref: "uploader",
                  staticClass: "upload-demo",
                  attrs: {
                    headers: { Authorization: _vm.Authorization },
                    action: "/api/ali/upload",
                    multiple: "",
                    data: _vm.uploadParam,
                    "auto-upload": true,
                    "show-file-list": false,
                    "file-list": _vm.design.unloginLogo,
                    limit: 1,
                    "http-request": _vm.logoUpload.elFileUpload,
                    "before-upload": _vm.onBeforeFileUpload,
                    "on-exceed": _vm.onHandleFileExceed,
                    "on-success": _vm.onHandleFileSuccess,
                    "before-remove": function (params) {
                      return _vm.onBeforeFileRemove(params)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { "text-align": "left" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            "border-color": "#3476f0",
                            color: "#3476f0",
                          },
                          attrs: { icon: "el-icon-plus", size: "mini" },
                        },
                        [_vm._v("上传logo")]
                      ),
                      _c("div", { staticClass: "el-upload__text" }),
                      _c("div", { staticClass: "el-upload__text" }, [
                        _vm._v(" 建议上传 90x90 正方形照片 "),
                      ]),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-form-item",
        { staticClass: "extra-item", attrs: { label: "页脚文字" } },
        [
          _c("el-input", {
            staticStyle: { height: "50px", width: "300px" },
            attrs: { placeholder: "" },
            model: {
              value: _vm.design.pageFoot,
              callback: function ($$v) {
                _vm.$set(_vm.design, "pageFoot", $$v)
              },
              expression: "design.pageFoot",
            },
          }),
        ],
        1
      ),
      _c(
        "draggable",
        {
          attrs: {
            options: {
              group: { name: "formItem" },
              sort: true,
              handle: ".extra-draggable",
            },
            animation: "300",
          },
          model: {
            value: _vm.design.extra,
            callback: function ($$v) {
              _vm.$set(_vm.design, "extra", $$v)
            },
            expression: "design.extra",
          },
        },
        [
          _vm._l(_vm.design.extra, function (item, index) {
            return [
              item.type === "richtext"
                ? _c(
                    "div",
                    { key: index, staticClass: "extra-item" },
                    [
                      _c(
                        "el-form-item",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "extra-draggable" },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "formListBtnMove",
                                          },
                                        }),
                                        _vm._v(" 标题"),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "config-item" },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                attrs: { placeholder: "标题" },
                                model: {
                                  value: item.title,
                                  callback: function ($$v) {
                                    _vm.$set(item, "title", $$v)
                                  },
                                  expression: "item.title",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "详情" } },
                        [
                          _c("richEditor", {
                            attrs: {
                              formId: _vm.businessId,
                              agencyId: "",
                              uploadPath: "designViews/{formId}/richEditor/",
                            },
                            model: {
                              value: item.config,
                              callback: function ($$v) {
                                _vm.$set(item, "config", $$v)
                              },
                              expression: "item.config",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              item.type === "video"
                ? _c(
                    "div",
                    { key: index, staticClass: "extra-item" },
                    [
                      _c(
                        "el-form-item",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "extra-draggable" },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "formListBtnMove",
                                          },
                                        }),
                                        _vm._v(" 标题"),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "config-item" },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                attrs: { placeholder: "标题" },
                                model: {
                                  value: item.title,
                                  callback: function ($$v) {
                                    _vm.$set(item, "title", $$v)
                                  },
                                  expression: "item.title",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "详情" } },
                        [
                          _vm._l(item.config.urls, function (file, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "upload-preview" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "config-item video-config-item",
                                  },
                                  [_vm._v(" " + _vm._s(file.url) + " ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "del",
                                    on: {
                                      click: function ($event) {
                                        return item.config.urls.splice(index, 1)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "close" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          _c(
                            "el-upload",
                            {
                              ref: "uploader",
                              refInFor: true,
                              staticClass: "upload-demo",
                              attrs: {
                                headers: { Authorization: _vm.Authorization },
                                action: "/api/ali/upload",
                                multiple: "",
                                data: _vm.uploadParam,
                                "auto-upload": true,
                                "show-file-list": false,
                                "file-list": item.config.urls,
                                limit: 1,
                                "http-request":
                                  _vm.uploadHandlers[index].elFileUpload,
                                "before-remove": function (params) {
                                  return _vm.onBeforeItemRemove(item, params)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "text-align": "left" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        "border-color": "#3476f0",
                                        color: "#3476f0",
                                      },
                                      attrs: {
                                        icon: "el-icon-plus",
                                        size: "mini",
                                      },
                                    },
                                    [_vm._v("上传视频")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              item.type === "pictures"
                ? _c(
                    "div",
                    { key: index, staticClass: "extra-item" },
                    [
                      _c(
                        "el-form-item",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "extra-draggable" },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "formListBtnMove",
                                          },
                                        }),
                                        _vm._v(" 标题"),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "config-item" },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                attrs: { placeholder: "标题" },
                                model: {
                                  value: item.title,
                                  callback: function ($$v) {
                                    _vm.$set(item, "title", $$v)
                                  },
                                  expression: "item.title",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      item.type === "pictures"
                        ? _c(
                            "el-form-item",
                            { key: index, attrs: { label: "图片高度" } },
                            [
                              _c(
                                "div",
                                { staticClass: "config-item" },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "200px" },
                                    attrs: { placeholder: "图片高度" },
                                    model: {
                                      value: item.config.height,
                                      callback: function ($$v) {
                                        _vm.$set(item.config, "height", $$v)
                                      },
                                      expression: "item.config.height",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      item.type === "pictures"
                        ? _c(
                            "el-form-item",
                            { key: index, attrs: { label: "图片高度" } },
                            [
                              _c(
                                "div",
                                { staticClass: "config-item" },
                                _vm._l(
                                  item.config.urls,
                                  function (file, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "upload-preview",
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              file.url +
                                              "?x-oss-process=image/resize,m_fixed,h_80,w_120",
                                          },
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "del",
                                            on: {
                                              click: function ($event) {
                                                return item.config.urls.splice(
                                                  index,
                                                  1
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("svg-icon", {
                                              attrs: { "icon-class": "close" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                              _c(
                                "el-upload",
                                {
                                  ref: "uploader",
                                  refInFor: true,
                                  staticClass: "upload-demo",
                                  attrs: {
                                    headers: {
                                      Authorization: _vm.Authorization,
                                    },
                                    action: "/api/ali/upload",
                                    multiple: "",
                                    data: _vm.uploadParam,
                                    "auto-upload": true,
                                    "show-file-list": false,
                                    "file-list": item.config.urls,
                                    limit: 50,
                                    "http-request":
                                      _vm.uploadHandlers[index].elFileUpload,
                                    "before-remove": function (params) {
                                      return _vm.onBeforeItemRemove(
                                        item,
                                        params
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { "text-align": "left" } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "border-color": "#3476f0",
                                            color: "#3476f0",
                                          },
                                          attrs: {
                                            icon: "el-icon-plus",
                                            size: "mini",
                                          },
                                        },
                                        [_vm._v("上传图片")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-dropdown",
            [
              _c("el-button", { attrs: { type: "primary" } }, [
                _vm._v(" 添加板块"),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.addExtra("richtext")
                        },
                      },
                    },
                    [_vm._v("富文本")]
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.addExtra("pictures")
                        },
                      },
                    },
                    [_vm._v("图片列表")]
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.addExtra("video")
                        },
                      },
                    },
                    [_vm._v("视频")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }