<template>
  <div>
    <el-form-item label="副标题">
      <el-input type="textarea" v-model="design.pageTitle" placeholder="" style="height: 50px; width: 300px"></el-input>
    </el-form-item>
    <el-form-item label="主题色">
      <el-color-picker v-model="design.themeColor" placeholder="" color-format="hex"></el-color-picker>
    </el-form-item>
    <el-form-item label="Banner图">
      <div>
        <img v-for="(file, index) in design.bannerFiles" :src="file.url + '?x-oss-process=image/resize,m_fixed,h_80,w_120'" :key="index" @click="designForm.cover = src" style="
            width: 90px;
            height: 60px;
            padding: 2px 5px;
            border-radius: 8px;
            cursor: pointer;
          " />
      </div>
      <el-upload v-if="formFilesUpload" ref="uploader" class="upload-demo" :headers="{ Authorization: Authorization }" action="/api/ali/upload" multiple :data="uploadParam" :auto-upload="true" :show-file-list="false" :file-list="design.bannerFiles" :limit="10"
                 :http-request="formFilesUpload.elFileUpload" :before-upload="onBeforeFileUpload" :on-exceed="onHandleFileExceed" :on-success="onHandleFileSuccess" :before-remove="(params) => onBeforeFileRemove(params)">
        <div style="text-align: left">
          <el-button style="border-color: #3476f0; color: #3476f0" icon="el-icon-plus" size="mini">上传banner图片</el-button>
          <div class="el-upload__text"></div>
          <div class="el-upload__text">
            <div v-if="type == '2'">
              建议上传宽高比为 4.5:1 的图片,例如: 1280 x 280，不超过2MB
            </div>
            <div v-else>
              建议上传宽高比为 2.8:1 的图片,例如: 1280 x {1280/2.8}，不超过2MB
            </div>
          </div>
        </div>
      </el-upload>
    </el-form-item>
     <el-form-item label="未登录Logo">
      <div>
        <img v-for="(file, index) in design.unloginLogo" :src="file.url + '?x-oss-process=image/resize,m_fixed,h_60,w_60'" :key="index" @click="designForm.cover = src" style="
            width: 60px;
            height: 60px;
            padding: 2px 5px;
            border-radius: 8px;
            cursor: pointer;
          " />
      </div>
      <el-upload v-if="logoUpload" ref="uploader" class="upload-demo" :headers="{ Authorization: Authorization }" action="/api/ali/upload" multiple :data="uploadParam" :auto-upload="true" :show-file-list="false" :file-list="design.unloginLogo" :limit="1"
                 :http-request="logoUpload.elFileUpload" :before-upload="onBeforeFileUpload" :on-exceed="onHandleFileExceed" :on-success="onHandleFileSuccess" :before-remove="(params) => onBeforeFileRemove(params)">
        <div style="text-align: left">
          <el-button style="border-color: #3476f0; color: #3476f0" icon="el-icon-plus" size="mini">上传logo</el-button>
           <div class="el-upload__text"></div>
          <div class="el-upload__text">
              建议上传 90x90 正方形照片
          </div>
        </div>
      </el-upload>
    </el-form-item>
    <el-form-item label="页脚文字" class="extra-item">
      <el-input v-model="design.pageFoot" placeholder="" style="height: 50px; width: 300px"></el-input>
    </el-form-item>

    <draggable v-model="design.extra" :options="{
                        group: { name: 'formItem' },
                        sort: true,
                        handle: '.extra-draggable'
                      }" 
                    animation="300">
                    
    <template v-for="(item, index) in design.extra">
       <div v-if="item.type === 'richtext'" :key="index" class="extra-item">
          <el-form-item >
            <template #label>
              <span class="extra-draggable"><svg-icon icon-class="formListBtnMove"/> 标题</span>
            </template>
            <div class="config-item">
              <el-input v-model="item.title" placeholder="标题" style="width: 200px"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="详情">
            <richEditor :formId="businessId" :agencyId="''" uploadPath="designViews/{formId}/richEditor/" v-model="item.config"></richEditor>
          </el-form-item>
      </div>
      <div v-if="item.type === 'video'" :key="index" class="extra-item">
          <el-form-item >
            <template #label>
              <span class="extra-draggable"><svg-icon icon-class="formListBtnMove"/> 标题</span>
            </template>
            <div class="config-item">
              <el-input v-model="item.title" placeholder="标题" style="width: 200px"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="详情">
            <div class="upload-preview" v-for="(file, index) in item.config.urls" :key="index">
              <div class="config-item video-config-item">
                {{file.url}}
              </div>
              <div class="del" @click="item.config.urls.splice(index, 1)">
              <svg-icon icon-class="close"/>
              </div>
            </div>
            <el-upload ref="uploader" class="upload-demo" :headers="{ Authorization: Authorization }" action="/api/ali/upload" multiple :data="uploadParam" :auto-upload="true" :show-file-list="false" :file-list="item.config.urls" :limit="1" :http-request="uploadHandlers[index].elFileUpload"
                      :before-remove="(params) => onBeforeItemRemove(item, params)">
              <div style="text-align: left">
                <el-button style="border-color: #3476f0; color: #3476f0" icon="el-icon-plus" size="mini">上传视频</el-button>
              </div>
            </el-upload>
          </el-form-item>
      </div>
      <div v-if="item.type === 'pictures'" :key="index" class="extra-item">
          <el-form-item >
            <template #label>
              <span class="extra-draggable"><svg-icon icon-class="formListBtnMove"/> 标题</span>
            </template>
            <div class="config-item">
              <el-input v-model="item.title" placeholder="标题" style="width: 200px"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="图片高度" v-if="item.type === 'pictures'" :key="index">
            <div class="config-item">
              <el-input v-model="item.config.height" placeholder="图片高度" style="width: 200px"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="图片高度" v-if="item.type === 'pictures'" :key="index">
            <div class="config-item">
              <div class="upload-preview" v-for="(file, index) in item.config.urls" :key="index">
                <img :src="file.url + '?x-oss-process=image/resize,m_fixed,h_80,w_120'" />
                <div class="del" @click="item.config.urls.splice(index, 1)">
                <svg-icon icon-class="close"/>
                </div>
              </div>
            </div>
            <el-upload ref="uploader" class="upload-demo" :headers="{ Authorization: Authorization }" action="/api/ali/upload" multiple :data="uploadParam" :auto-upload="true" :show-file-list="false" :file-list="item.config.urls" :limit="50" :http-request="uploadHandlers[index].elFileUpload"
                    :before-remove="(params) => onBeforeItemRemove(item, params)">
              <div style="text-align: left">
                <el-button style="border-color: #3476f0; color: #3476f0" icon="el-icon-plus" size="mini">上传图片</el-button>
              </div>
            </el-upload>
          </el-form-item>
      </div>
      </template>
      </draggable>





     

    <el-form-item>
      <el-dropdown>
        <el-button type="primary">
          添加板块<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="addExtra('richtext')">富文本</el-dropdown-item>
          <el-dropdown-item @click.native="addExtra('pictures')">图片列表</el-dropdown-item>
          <el-dropdown-item @click.native="addExtra('video')">视频</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-form-item>
  </div>
</template>
<script>
import { getToken } from "@/utils/auth";
import { aliUploader } from "@/utils/oss";

import richEditor from "@/components/RichEditor";
import { saveFormDesign, getFormDesign } from "@/api/system/designView";
export default {
  components: {
    richEditor,
  },
  props: {
    businessId: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "1",
    },
  },
  data () {
    return {
      design: {
        pageTitle: "",
        bannerFiles: [],
        unloginLogo: [],
        extra: [],
        themeColor: "",
      },
      Authorization: "",
      uploadParam: {
        folder: "formFile",
      },
      formFilesUpload: null,
      logoUpload:null,
      uploadHandlers: [],
    };
  },
  created () {
    this.Authorization = getToken();
    getFormDesign(this.type, this.businessId).then((res) => {
      if (res && res.fieldConfig && Array.isArray(res.fieldConfig)) {
        res.fieldConfig.forEach((x) => {
          this.design[x.type] = x.config;
        });
        if (this.design["extra"] === undefined) {
          this.design["extra"] = [];
        }
        this.design.extra.forEach((x) => {
          if (x.type === "pictures" || x.type == "video") {
            this.newUploadHandler(x);
          }
        });
      }
    });

    //Banner图上传组件
    this.formFilesUpload = aliUploader(
      false,
      {
        uploadPath: `designViews/${this.businessId}/pictures/`,
      },
      () => {
        return this.design.bannerFiles;
      }
    );
    //logo上传组件
    this.logoUpload = aliUploader(
      false,
      {
        uploadPath: `designViews/${this.businessId}/pictures/`,
      },
      () => {
        return this.design.unloginLogo;
      }
    );
  },
  methods: {
    saveDesign () {
      return new Promise((resolve) => {
        let fieldConfig = [];

        Object.keys(this.design).forEach((key) => {
          fieldConfig.push({
            type: key,
            config: this.design[key],
          });
        });

        saveFormDesign({
          businessId: this.businessId,
          fieldConfig,
          type: this.type,
        }).then((res) => {
          resolve();
        });
      });
    },
    // 附件上传
    onHandleFileExceed () {
      this.$message.warning(`当前限制添加10个附件！`);
    },
    onBeforeFileUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.warning(`上传附件大小不能超过2MB！`);
      }
      if (isLt2M) {
      } else {
        return isLt2M;
      }
    },
    onHandleFileSuccess (response, file, fileList) {
      const _this = this;
      if (!_this.isBlank(response)) {
      }
    },
    onBeforeFileRemove (params) {
      const tmp_path = params.name;
      const index = this.design.bannerFiles.findIndex(
        (item) => item.name == tmp_path
      );
      if (index != -1) {
        this.design.bannerFiles.splice(index, 1);
      }
    },
    onBeforeItemRemove (extraItem, params) {
      const tmp_path = params.name;
      const index = extraItem.config.urls.findIndex(
        (item) => item.name == tmp_path
      );
      if (index != -1) {
        extraItem.config.splice(index, 1);
      }
    },
    addExtra (type) {
      if (type === "video") {
        let newItem = {
          type: type,
          config: {
            urls: [],
          },
          formFilesUpload: null,
        };

        this.newUploadHandler(newItem);

        this.design["extra"].push(newItem);
      } else if (type === "pictures") {
        let newItem = {
          type: type,
          config: {
            height: "",
            urls: [],
          },
          formFilesUpload: null,
        };

        this.newUploadHandler(newItem);

        this.design["extra"].push(newItem);
      } else {
        this.design["extra"].push({
          type: type,
          config: null,
        });
      }
    },
    newUploadHandler (newItem) {
      var formFilesUpload = aliUploader(
        false,
        {
          uploadPath: `designViews/${this.businessId}/pictures/`,
        },
        () => {
          return newItem.config.urls;
        }
      );
      this.uploadHandlers.push(formFilesUpload);
    },
  },
  watch: {
    design: {
      deep: true,
      handler: function () {
        this.$emit("change");
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.config-item{
  margin-bottom:10px;
  line-height:20px;
}
.video-config-item{
  border:solid 1px #c9c9c9;
  background-color:#f5f5f5;
  box-sizing: border-box;
  padding: 2px 5px;
}
.extra-item{
  padding-bottom: 3px;
  border-bottom:solid 1px #f2f2f2;
  margin-bottom: 7px;
  ::v-deep .el-form-item{
    margin-bottom:3px;
  }
}
.extra-draggable{
  cursor: move;
}
.upload-preview {
  position: relative;  
  height: 60px;
  border-radius: 8px;
  margin: 0px 10px 5px 0px;
  cursor: pointer;
  display: inline-block;
  img {
    height: 60px;
    border-radius: 8px;
    cursor: pointer;
  }
  .del{
      position: absolute;
      top: -8px;
      right: -7px;
      width: 18px;
      height: 18px;
      line-height: 18px;
      color: white;
      font-size: 12px;
      display:inline-flex;
      justify-content: center;
      align-items: center;
      background-color: rgb(136, 24, 24);
      border-radius: 50%;
      .svg-icon{
        width:14px;
        height:14px;
        display:inline-block;
        margin:0px;
        box-sizing: border-box;
      }
  }
}
</style>
