var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      staticClass: "el-upload-list el-upload-list--text",
      staticStyle: { "text-align": "left" },
    },
    _vm._l(_vm.fileList, function (file, index) {
      return _c(
        "li",
        {
          key: index,
          staticClass: "el-upload-list__item is-undefined",
          attrs: { tabindex: "0" },
        },
        [
          _c("div", { staticClass: "upload-file-list" }, [
            _c(
              "span",
              { staticClass: "upload-file-name", attrs: { title: file.name } },
              [
                _c("span", {
                  staticClass: "ico-file",
                  class: _vm.fileType(file.name),
                }),
                _vm._v(" " + _vm._s(file.name) + " "),
                _vm.showSize
                  ? _c("span", { staticStyle: { "margin-left": "10px" } }, [
                      _vm._v(_vm._s(_vm.countSize(file))),
                    ])
                  : _vm._e(),
              ]
            ),
            !file.percent || file.percent >= 100
              ? _c(
                  "a",
                  {
                    staticClass: "file-action",
                    staticStyle: { margin: "0px" },
                    attrs: { download: "", href: file.url, target: "_blank" },
                  },
                  [
                    _c("span", {
                      staticClass: "el-icon-download file-action",
                      attrs: { title: "下载" },
                    }),
                  ]
                )
              : _vm._e(),
            _c("span", {
              staticClass: "el-icon-delete file-action",
              attrs: { title: "移除" },
              on: {
                click: function ($event) {
                  return _vm.onDeleteClick(index)
                },
              },
            }),
            !file.isStop && file.percent > 0 && file.percent < 100
              ? _c("span", {
                  staticClass: "el-icon-video-pause file-action",
                  attrs: { title: "暂停" },
                  on: {
                    click: function ($event) {
                      return _vm.onCancelClick(index)
                    },
                  },
                })
              : _vm._e(),
            !!file.isStop && file.percent > 0 && file.percent < 100
              ? _c("span", {
                  staticClass: "el-icon-video-play file-action",
                  attrs: { title: "继续" },
                  on: {
                    click: function ($event) {
                      return _vm.onResumeClick(index)
                    },
                  },
                })
              : _vm._e(),
          ]),
          file.percent > 0 && file.percent < 100
            ? _c("el-progress", { attrs: { percentage: file.percent } })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }