<template>
  <ul class="el-upload-list el-upload-list--text" style="text-align: left;">
    <li tabindex="0" class="el-upload-list__item is-undefined" v-for="(file,index) in fileList" :key="index">
      <div class="upload-file-list">
        <span :title="file.name" class="upload-file-name">
          <span class="ico-file" :class="fileType(file.name)"></span>
          {{file.name}}
          <span style="margin-left:10px;" v-if="showSize">{{countSize(file)}}</span>
        </span>
        <a download="" :href="file.url" target="_blank" v-if="(!file.percent)||file.percent>=100" class="file-action" style="margin:0px;">
          <span title="下载" class="el-icon-download file-action"></span>
        </a>
        <span title="移除" @click="onDeleteClick(index)" class="el-icon-delete file-action"></span>
        <span title="暂停" @click="onCancelClick(index)" class="el-icon-video-pause file-action" v-if="!file.isStop && file.percent>0&& file.percent<100"></span>
        <span title="继续" @click="onResumeClick(index)" class="el-icon-video-play file-action" v-if="!!file.isStop && file.percent>0 && file.percent<100"></span>
      </div>
      <el-progress :percentage="file.percent" v-if="file.percent>0&& file.percent<100"></el-progress>
    </li>
  </ul>

</template>
<script>
import { deleleteFile } from '@/api/system/form'
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    showSize:{
      type:Boolean,
      default:true
    },
    uploader:{
      type: Object|Function
    }
  },
  data() {
    return {
      uploadCount: 0,
      fileList: []
    }
  },
  created() {
  },
  watch: {
    value: function (val) {
      console.log("watch", this.value)
      this.fileList = this.value;
      this.$emit("fileListChanged", this.fileList)
    }
  },
  methods: {
    onDeleteClick(index) {
      if (this.fileList[index].percent > 0 && this.fileList[index].percent < 100) {
        this.onCancelClick(index);
      }
      let delFile=this.fileList.splice(index, 1);
      if(delFile[0].fileId){
        deleleteFile(delFile[0].fileId)
      }
      this.$emit("input", this.fileList);
    },
    onResumeClick(index) {
      this.fileList[index].isStop = false;

      this.fileList[index].resume()
      this.$forceUpdate();
    },
    onCancelClick(index) {
      this.fileList[index].isStop = true;

      if(this.fileList[index].stop)
        this.fileList[index].stop()
      this.$forceUpdate();
    },
    countSize(countFile, bracket = true) {
      let sizeName = ["B", "KB", "MB", "GB"];
      if (!countFile.size) {
        return "";
      }
      let size = countFile.size;
      let i = 0;

      while (size > 1024) {
        size = size / 1024;
        i++;
      }
      if (bracket) {
        return "[" + size.toFixed(2) + sizeName[i] + "]";
      } else {
        return size.toFixed(2) + sizeName[i];
      }
    },
    fileType(fileName) {
      return "ico-" + fileName.substring(fileName.lastIndexOf(".") + 1);
    }
  }
}
</script>
<style scoped lang="scss">
.el-upload-list__item {
  // padding: 0px 10px;
  box-sizing: border-box;
  cursor: pointer;
  // padding-bottom: 5px;

  .el-progress {
    top: initial;
    bottom: 2px;
    right: 10px;
    left: 10px;
    width: auto;
    box-sizing: border-box;
  }
}

.upload-file-list {
  font-size: 14px;
  line-height: 35px;
  padding-bottom: 5px;

  .file-action {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 16px;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
  }
}

.upload-file-list {
  display: inline-flex;
  align-items: center;
}
.upload-file-name {
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
  a {
    height: 29px;
    line-height: 30px;
    display: inline-block;
  }
}
.ico-file {
  height: 30px;
  width: 30px;
  margin-right: 10px;
  display: inline-block;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
</style>
